<script>
import Layout from "../../layouts/main";
const brandController = require("@/controllers/endpoint/brands");
const clientController = require("@/controllers/endpoint/clients");
const contentController = require("@/controllers/endpoint/content_plannings");
const userController = require("@/controllers/endpoint/user");
import VueMonthlyPicker from "vue-monthly-picker";
import Swal from "sweetalert2";
import "@/assets/scss/buttons_content.scss";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

/**
 * Shops Component
 */
export default {
  components: {
    Layout,
    VueMonthlyPicker,
    DatePicker
  },
  data() {
    return {
        options_search:[
          { value: 'id', text: 'ID' },
          { value: 'creator', text: 'Creator' },
          { value: 'objective', text: 'Objective' },
          { value: 'week', text: 'Week' },
          { value: 'month', text: 'Month' },
          { value: 'year', text: 'Year' },
          { value: 'range', text: 'Range' },
        ],
      selected_search:'id',
      searchInput:'',
      sort: "ASC",
      rangedates: '',
      monthLabels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      user: {},
      newContent: {
        brand_id: this.$route.params.id,
        Objective: "",
        type: 1,
        week: "1",
        year: "",
        month: "",
        status: "0",
        rol: "",
      },
      editContent: {
        id: 0,
        brand_id: this.$route.params.id,
        Objective: "",
        type: 0,
        week: "",
        year: "",
        month: "",
        status: "",
        rol: "",
        _i: 0,
      },
      spinner: true,
      title: "",
      brandId: this.$route.params.id,
      brand: [],
      client: [],
      content: [],
      url: {
        brands: "",
      },
      posts: {},
      currentPage: 1,
      totalPages: 1,
      rows: 1,
    };
  },
  watch: {
    currentPage() {
      this.spinner = 1;
      this.contentData(this.brandId, this.currentPage);
    },
  },
  computed: {
    disabledForm() {
      return (
        this.newContent.Objective == "" ||
        this.newContent.month == ""
      );
    },
    disabledWeekly() {
      return (
        this.newContent.Objective == "" ||
        this.newContent.week == ""
      );
    },
     disabledrange() {
      return (
        this.newContent.Objective == "" ||
        this.rangedates == ""
      );
    },
    disabledForm2() {
      return (
        this.editContent.Objective == "" ||
        this.editContent._i == "" ||
        this.editContent.week == ""
      );
    },
  },
  created() {
    this.userData();
    this.brandData();
    this.contentData(this.brandId, 1);
  },
  methods: {
     searchChange(){
   
        if(this.searchInput == ''){
        this.contentData(this.brandId, 1);
        }else if(this.searchInput !== '' && this.selected_search == 'id'){
        this.search("id")    
        }else if(this.searchInput !== '' && this.selected_search == 'week'){
        this.search("week")       
        }else if(this.searchInput !== '' && this.selected_search == 'month'){
        this.search("month")       
        }else if(this.searchInput !== '' && this.selected_search == 'year'){
        this.search("year")       
        }else if(this.searchInput !== '' && this.selected_search == 'range'){
        this.search("range")       
        }else if(this.searchInput !== '' && this.selected_search == 'creator'){
        this.search("creator")       
        }else if(this.searchInput !== '' && this.selected_search == 'objective'){
        this.search("objective")       
        }
    },
    search(type){
    const dataContent = contentController.search(type, this.searchInput);
      dataContent
        .then((response) => {
          //Succesfully result
          this.content = response.data
          console.log(this.content)
        })
        .catch((err) => {
          //Error result
          console.error(err);
        });
    },
    changeSort(sort){
    this.sort = sort
    this.contentData(this.brandId, 1);
    },
    addContent() {
      let data = {}

      if(this.newContent.type == 1){
      console.log(this.newContent.month)
      let date = this.newContent.month.split("/")
      let month = date[1]
      let year = date[0]
      data = {
            "brand_id": this.brandId,
            "objetive": this.newContent.Objective,
            "type": this.newContent.type,
            "information":"",
            "range": null,
            "week": null,
            "year": month,
            "month": year,
            "idcreator":this.user.id,
            "namecreator":`${this.user.name} ${this.user.lastname}`,
            "rol":this.user.rol
      }
      }else if(this.newContent.type == 0){
        let date = this.newContent.week.split("/")
      data = {
            "brand_id": this.brandId,
            "objetive": this.newContent.Objective,
            "type": this.newContent.type,
            "information":"",
            "range": null,
            "week": date[0],
            "year": date[3],
            "month": date[1],
            "idcreator":this.user.id,
            "namecreator":`${this.user.name} ${this.user.lastname}`,
            "rol":this.user.rol
      }
      }else if(this.newContent.type == 2){
      data = {
            "brand_id": this.brandId,
            "objetive": this.newContent.Objective,
            "type": this.newContent.type,
            "information":"",
            "range":`${this.rangedates}`,
            "week": null,
            "year":  null,
            "month": null,
            "idcreator":this.user.id,
            "namecreator":`${this.user.name} ${this.user.lastname}`,
            "rol":this.user.rol
      }
      }
     

      console.log(data);
      const addContent = contentController.create(data);
      addContent
        .then((response) => {
          //Succesfully result
          let data = response.data.description;
          if (response.request.status == 201) {
            this.alert(data, "success");
            this.closeModal();
            this.resetData();
            this.spinner = true;
            this.contentData(this.brandId, 1);
          } else {
            this.alert(data, "warning");
          }
        })
        .catch((err) => {
          this.alert(err, "danger"); //Error result
        });
    },
    viewPosts(data){
      const n = this.url.brandName.replace(/ /g, "-").toLowerCase();
      const s = data.type ? 'monthly' : 'weekly';
      const link = `/brand/content-planning/${n}/${s}/${data.month}/${data.year}/${data.id}`;
      this.$router.push(link);
    },
    edit(data) {
      this.editContent.type = data.type ? 1 : 0;
      this.editContent.Objective = data.Objective;
      this.editContent.status = data.status ? 1 : 0;
      this.editContent.week = data.week;
      //const d = new Date();
      //d.setFullYear(data.year, data.month - 1, 1);
      this.editContent._i = data.year.toString() + "/" + data.month.toString();
      this.editContent.id = data.id;
      this.$refs["edit-content-modal"].show();
      console.log(this.editContent);
    },
    updateRecord() {
      this.editContent.rol = this.user.rol;
      console.log(this.editContent._i);
      let añoMes = "";
      if (typeof this.editContent._i == "string") {
        añoMes = this.editContent._i.split("/");
      } else {
        añoMes = this.editContent._i._i.split("/");
      }
      this.editContent.year = añoMes[0];
      this.editContent.month = añoMes[1];
      const updateContent = contentController.update(this.editContent);
      updateContent
        .then((response) => {
          //Succesfully result
          let data = response.data.description;
          this.alert(data, "success");
          this.closeModal();
          this.resetData();
          this.spinner = true;
          this.contentData(this.brandId, 1);
        })
        .catch((err) => {
          this.alert(err, "danger"); //Error result
        });
    },
    deletee(dataa) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!\nThe content related to this client will be delete it too",
          icon: "warning",
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No, cancel!",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            let data = {
              id: dataa.id,
              rol: this.user.rol,
            };
            console.log(data);
            const deleteContent = contentController.delete(data);
            deleteContent
              .then((response) => {
                //Succesfully result
                response;
                swalWithBootstrapButtons.fire(
                  "Deleted!",
                  "Your content planning has been deleted.",
                  "success"
                );
                this.resetData();
                this.spinner = true;
                this.contentData(this.brandId, 1);
              })
              .catch((err) => {
                //Error result
                console.error(err);
              });
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "Your client is safe :)",
              "error"
            );
          }
        });
    },
    alert(message, variant) {
      this.$bvToast.toast(`${message}`, {
        title: "CONTENT PLANNING ALERT",
        variant: variant,
        solid: true,
      });
    },
    closeModal() {
      this.$refs["new-content-modal"].hide();
      this.$refs["edit-content-modal"].hide();
    },
    resetData() {
      this.newContent = {
        brand_id: this.$route.params.id,
        Objective: "",
        type: 1,
        week: "1",
        year: "",
        month: "",
        status: "0",
        rol: "",
      };
    },
    userData() {
      const dataUser = userController.dataUser();
      dataUser
        .then((response) => {
          //Succesfully result
          this.user = response.data[0];
          this.brand.rol = this.user.rol;
        })
        .catch((err) => {
          //Error result
          console.error(err);
        });
    },
    brandData() {
      const dataClient = brandController.findID(this.brandId);
      dataClient
        .then((response) => {
          //Succesfully result
          this.brand = response.data[0];
          this.clientData(this.brand.client_id);
        })
        .catch((err) => {
          //Error result
          console.error(err);
        });
    },
    clientData(client_id) {
      const dataClient = clientController.findID(client_id);
      dataClient
        .then((response) => {
          //Succesfully result
          this.client = response.data[0];
          this.linkUrl();
        })
        .catch((err) => {
          //Error result
          console.error(err);
        });
    },
    contentData(brand, page) {
      const dataContent = contentController.listPaginated(brand, page, this.sort);
      dataContent
        .then((response) => {
          //Succesfully result
          this.currentPage = parseInt(response.posts.current_page);
          this.rows = response.pagination.total;
          this.totalPages = this.rows / 10;
          this.content = response.posts.data.rows;
          console.log(response);
          setTimeout(() => {
            this.spinner = 0;
          }, "500");
        })
        .catch((err) => {
          //Error result
          console.error(err);
        });
    },
    linkUrl() {
      let clientName = this.client.name.replace(/ /g, "-").toLowerCase();
      let urlBrands = `/client/${clientName}/brands/${this.client.id}`;
      this.url = {
        brands: urlBrands,
        brandName: `${this.brand.name
          .charAt(0)
          .toUpperCase()}${this.brand.name.slice(1)}`,
      };
      this.title = this.brand.name.toUpperCase();
    },
    changePage(pa) {
      this.currentPage = pa;
    },
    test(){
 
               }
  },
};
</script>
<style scoped>

.button-client-form{
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 8px;
    padding-bottom: 8px;
    color: #f9faff !important;
    background-color: #247ba0 !important;
    font-weight: bold !important;;
    border-radius: 30px !important;
    border: 2px solid #247ba0;
}
.button-client-form:hover{
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 8px;
    padding-bottom: 8px;
    color: #f9faff !important;
    background-color: #011c27 !important;
    font-weight: bold !important;
    border-radius: 30px !important;
    border: 2px solid #f9faff;
}
.button-view-client{
    border: 2px solid #595959;
    position: relative;
    background-color:transparent !important;
    display: inline-flex;
    vertical-align: middle;
    border-radius: 10px;
    color: #011c27 !important;
    font-family: "Inter", sans-serif !important;
    font-weight:400 !important;
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 14px;
  }
  .button-view-client:hover{
    border: 2px solid #011c27;
    position: relative;
    background-color:#011c27 !important;
    display: inline-flex;
    vertical-align: middle;
    border-radius: 10px;
    color: white !important;
    font-family: "Inter", sans-serif !important;
    font-weight:400 !important;
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 14px;
  }
  .btn-group, .btn-group-vertical {
    padding-top: 3px;
    padding-bottom: 3px;
    margin-right: 15px;
    border: 2px solid #595959;
    position: relative;
    display: inline-flex;
    vertical-align: middle;
    border-radius: 10px;
    color: #011c27;
    font-family: "Inter", sans-serif !important;
    font-weight: 400 !important;
    font-size: 14px;
}
.btn-group > .btn, .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto;
    font-weight: 500;
    }
.id-box-brand{
    font-weight: 600;
    background-color: #011c27;
    color: white;
    font-family: nunito;
    border-radius: 5px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 20px;
}
.anchore-menu {
  color:#011c27;
  font-family: "Inter", sans-serif;
  font-weight: 500;
}
.anchore-menu:hover {
  color:#247ba0;
  font-family: "Inter", sans-serif;
  font-weight: 500;
}
.second-menu {
pointer-events: none;
color:#595959;
font-family: "Inter", sans-serif;
font-weight: 500;
}
.title-content {
  font-size: 40px;
  margin: 0 0 0px 0;
  color: #011c27;
  margin-bottom: 30px;
  font-family: nunito;
  font-weight: 600;
  pointer-events: none;
}
.dot-content {
  pointer-events: none;
  color:#247ba0;
  font-family: nunito;
  font-weight: 600
}
.fade-in {
  animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  -ms-animation: fadeIn s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.button-brands {
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
  color: #f9faff !important;
  background-color: #4f37ea !important;
  font-weight: bold !important;
  border-radius: 30px !important;
  border: 2px solid #4f37ea;
}
.button-brands:hover {
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
  color: #f9faff !important;
  background-color: #262626 !important;
  font-weight: bold !important;
  border-radius: 30px !important;
  border: 2px solid #f9faff;
}
.vue-monthly-picker .text {
  display: none;
}
.button-new-planning{
    border: 2px solid #247ba0;
    background-color:#247ba0 !important;
    border-radius: 10px;
    color: white;
    font-family: "Inter", sans-serif !important;
    font-weight: 400 !important;
    font-size: 14px;
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .button-new-planning:hover{
    border: 2px solid #011c27;
    background-color: #ffffff !important;
    border-radius: 10px;
    color: #595959 !important;
    font-family: "Inter", sans-serif !important;
    font-weight: 400 !important;
    font-size: 14px;
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .table th, .table td {
    border-bottom: 2px solid #d3d3d3;
  }
  .table thead th {
      border-bottom: 1px solid #e9e9e9;
      border-top: 1px solid #ffffff;
  }
  table th, .table td {
      border-top: 1px solid #ffffff;
  }
  .tr-table-brand{
  font-family: nunito;
  color: #595959;
  font-weight: 600;
  } 
  .tbody-table-brand{
  font-family: "Inter", sans-serif;
  color: #011c27;
  font-weight: 500;
}
table th, .table td {
    vertical-align: middle;
}
</style>
<template>
  <Layout>
    <div style="padding-left:30px; padding-right:30px;">
    <div class="text-lg-left">
      <ul class="list-inline mb-0">
        <li class="list-inline-item">
          <router-link :to="`${url.brands}`" class="font-w600 text-dual">
            <a class="anchore-menu">Brands</a>
          </router-link>
        </li>
        <li class="list-inline-item">
          <i
            style="color: #3521b5"
            class="fas fa-angle-right"
          ></i>
        </li>
        <li class="second-menu list-inline-item">
          {{ url.brandName }}
        </li>
      </ul>
    </div>
    <div class="row">
      <div class="col-lg-8 text-lg-left">
        <h5 class="title-content">
          Content planning of <span class="dot-content">{{ this.title }}</span>.
        </h5>
      </div>
      <div class="col-lg-4 text-lg-right">
        <button v-b-modal.content-popup-form class="button-new-planning" style="">Add new planning</button>
        <!--START MODAL-->
        <b-modal
          ref="new-content-modal"
          id="content-popup-form"
          title="New Content Planning"
          hide-footer
          centered
          title-class="font-18"
        >
          <div class="row">
            <div class="col-lg-12">
              <div class="form-group">
                <label for="name">Objective</label>
                <input
                  v-model="newContent.Objective"
                  type="text"
                  class="form-control"
                  id="name"
                  placeholder="Enter Content Objective"
                />
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <label for="description">Type</label>
                <select class="form-control" v-model="newContent.type">
                  <option value="1">Monthly</option>
                  <option value="0">Weekly</option>
                  <option value="2">Select a date</option>
                </select>
              </div>
            </div>
            <div  v-if="newContent.type == 1" class="col-lg-12">
              <div class="form-group">
                <label for="description">Month</label>
                <date-picker placeholder="Select month" v-model="newContent.month" valueType="MM/YYYY" type="month"></date-picker>
              </div>
            </div>
            <div v-if="newContent.type == 0" class="col-12">
              <div class="form-group">
                <label for="description">Week</label>
                <date-picker  placeholder="Select week"  v-model="newContent.week" valueType="w/MM/DD/YYYY" type="week"></date-picker>               
              </div>
            </div>
            <div v-if="newContent.type == 2" class="col-12">
              <div class="form-group">
                <label for="description">Select a date</label>
                    <date-picker placeholder="Select a range of date" valueType="MM/DD/YYYY" v-model="rangedates" range></date-picker>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div v-if="newContent.type == 0" class="text-left">
                 <button
                  v-on:click="addContent()"
                  :disabled="disabledWeekly"
                  class="btn button-client-form btn-primary"
                >
                  ADD NEW
                </button>
              </div>
              <div v-if="newContent.type == 1" class="text-left">
                  <button
                  v-on:click="addContent()"
                  :disabled="disabledForm"
                  class="btn button-client-form btn-primary"
                >
                  ADD NEW
                </button>
              </div>
              <div v-if="newContent.type == 2" class="text-left">
                  <button
                  v-on:click="addContent()"
                  :disabled="disabledrange"
                  class="btn button-client-form btn-primary"
                >
                  ADD NEW
                </button>
                   <button
                  v-on:click="test()"
                  
                  class="btn button-client-form btn-primary"
                >
                  ADD test
                </button>
              </div>
            </div>
          </div>
        </b-modal>
        <!--END MODAL-->
      </div>
    </div>
        <div class="row">
      <div  class="col-lg-6 text-lg-left">
          <b-input-group class="mb-2">
    <b-input-group-prepend is-text>
                <i style="font-size: 0.9rem; font-weight: 400; line-height: 1.5; color: #011c27;" class="ri-search-eye-line align-middle"></i>
    </b-input-group-prepend>
    <b-form-input @input="searchChange()" v-model="searchInput"  size="10" placeholder="Search..."></b-form-input>
     <template #append>
       <b-form-select @input="searchChange()" style="border-radius: 0px" v-model="selected_search" :options="options_search"></b-form-select>
    </template>
  </b-input-group>
      </div>
      <div class="button-div col-lg-6 text-lg-right">
          <b-dropdown toggle-class='dropdown-clients' variant='none' id="dropdown-clients" text="Dropdown Button">
                          <template style="background-color:black;" slot="button-content">
                           <i class="ri-filter-3-line"></i>
                            Filters
                          </template>
                          <b-dropdown-item v-on:click="changeSort('ASC')">Asc</b-dropdown-item>
                          <b-dropdown-item v-on:click="changeSort('DESC')">Desc</b-dropdown-item>
                         </b-dropdown>
      </div>
      
    </div>
    <div style="padding-top: 150px" v-if="spinner" class="text-center">
      <b-spinner
        style="width: 3rem; height: 3rem; color:#011c27;" type="grow"
        label="Text Centered Large Spinner"
      ></b-spinner>
    </div>
    <div v-if="!spinner" class="row fade-in">
      <div class="col-lg-12">
            <h2 v-if="rows==0" class="font-w600 text-dual"></h2>
            <div v-if="rows!=0" class="table-responsive">
              <table class="table mb-0">
                <thead>
                  <tr class="tr-table-brand">
                    <th  width="2%" style="text-align: center;">ID</th>
                    <th>Type</th>
                    <th>Information</th>
                    <th>Objective</th>
                    <th>Created by</th>
                    <th>Created at</th>
                    <th v-if="user.rol > 0 && user.rol <= 8">Action</th>
                  </tr>
                </thead>
                <tbody  class="tbody-table-brand">
                  <tr  v-for="(data, index) in content" :key="index">
                    <th><span class="id-box-brand">{{data.id}}</span></th>
                    <td><span v-if="data.type == 1">Monthly</span><span v-if="data.type == 0">Weekly</span><span v-if="data.type == 2">Range</span></td>
                    <td>
                    <span v-if="data.type == 1 || data.type == 0">
                    <b>Year:</b> {{ data.year }}<br>
                    <b>Month:</b> {{ monthLabels[data.month - 1] }}<br  v-if="data.week !== null">
                    <b v-if="data.week !== null">Week:</b> {{  data.week }}
                    </span>
                    <span v-if="data.type == 2">
                    <b>Start:</b> {{ data.range.split(',')[0] }}<br>
                    <b>End:</b> {{ data.range.split(',')[1] }}
                    </span>
                    </td>
                    <td>{{ data.objetive }}</td>
                    <td>{{data.namecreator}}</td>
                    <td>{{ data.createdAt.substring(0,10) }}</td>
               
                    <td v-if="user.rol > 0 && user.rol <= 8">
                      <b-dropdown v-if="user.rol > 0 && user.rol <= 8" toggle-class='dropdown-clients' variant='none' id="dropdown-clients" text="Dropdown Button">
                          <template style="background-color:black;" slot="button-content">
                            Manage
                            <i class="mdi mdi-chevron-down"></i>
                          </template>
                          <b-dropdown-item v-on:click="edit(data)">Edit</b-dropdown-item>
                          <b-dropdown-item v-on:click="deletee(data)">Delete</b-dropdown-item>
                         </b-dropdown>
                         <button class="button-view-client" v-on:click="viewPosts(data)">View</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
        
      </div>
    </div>
    <!-- end row -->
    <!--START MODAL-->
    <b-modal
      ref="edit-content-modal"
      id="contentEdit-popup-form"
      title="Edit Content Planning"
      hide-footer
      centered
      title-class="font-18"
    >
      <div class="row">
        <div class="col-lg-12">
          <div class="form-group">
            <label for="name">Objective</label>
            <input
              v-model="editContent.Objective"
              type="text"
              class="form-control"
              id="name"
              placeholder="Enter Content Objective"
            />
          </div>
        </div>
       
        <div class="col-lg-12">
          <div class="form-group">
            <label for="description">Type</label>
            <select class="form-control" v-model="editContent.type">
              <option value="1">Monthly</option>
              <option value="0">Weekly</option>
            </select>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="form-group">
            <label for="description">Month</label>
            <vue-monthly-picker
              placeHolder="Select a Month"
              dateFormat="YYYY/MM"
              :monthLabels="monthLabels"
              v-model="editContent._i"
            >
            </vue-monthly-picker>
          </div>
        </div>
        <div v-if="editContent.type == 0" class="col-12">
          <div class="form-group">
            <label for="description">Week</label>
            <select class="form-control" v-model="editContent.week">
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </select>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="description">Status</label>
            <select class="form-control" v-model="editContent.status">
              <option value="1">Published</option>
              <option value="0">Draft</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="text-left">
            <button
              v-on:click="updateRecord()"
              :disabled="disabledForm2"
              class="btn button-client-form btn-primary"
            >
              UPDATE
            </button>
          </div>
        </div>
      </div>
    </b-modal>
    <!--END MODAL-->

    <div   v-if="!spinner && rows!=0" class="pagination fade-in">
      <a
        v-if="this.currentPage > 1"
        href="#"
        v-on:click="changePage(currentPage - 1)"
        >&laquo;</a
      >
      <a v-if="this.currentPage == 1"  style="cursor: not-allowed; color: #011c27; font-family: Inter; font-weight: 500;"
        >&laquo;</a
      >
      <a
        v-if="this.currentPage > 1"
        href="#"
        v-on:click="changePage(currentPage - 1)"
        >{{ currentPage - 1 }}</a
      >
      <a class="active" href="#">{{ currentPage }}</a>
      <a
        href="#"
        v-if="this.totalPages > this.currentPage"
        v-on:click="changePage(currentPage + 1)"
        >{{ currentPage + 1 }}</a
      >
      <a
        href="#"
        v-if="this.totalPages > this.currentPage"
        v-on:click="changePage(currentPage + 1)"
        >&raquo;
      </a>
      <a
        style="cursor: not-allowed; color: #011c27; font-family: Inter; font-weight: 500;"
        
        v-if="this.totalPages <= this.currentPage"
        >&raquo;
      </a>
    </div>
    </div>
  </Layout>
</template>

<style>
.pagination {
  display: inline-block;
}
.pagination a {
  color: #011c27;
  border-radius: 30px;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  font-family: "Inter", sans-serif;
  font-weight: 500;
}

.pagination a.active {
  color: #011c27 !important;
  font-family: "Inter", sans-serif;
  font-weight: 500;
}

.pagination a:hover:not(.active) {
    color: #011c27 !important;
    font-family: "Inter", sans-serif;
    font-weight: 500;
}
</style>